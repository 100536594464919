@import './../../../vars.scss';


.content-demo {
  min-height: 85vh;
  background-image: url('/img/010_ilustracion_1-8.png'), url('/img/011_ilustracion-8.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.info {
  &-header {
    display: flex;
    flex-direction: row;
    line-height: .5;
    align-items: center;

    &__logo {
      margin-right: 4rem;

      img {
        width: 7rem;
      }
    }

    &__title {
      font-size: 1.7rem;
      h1 {
        color: #9C0AE8;
        font-weight: 600;
      }

      h2 {
        color: #9C0AE8;
        font-weight: 400;
      }
    }
  }

  &-content {
    margin: 2rem 0 0 11rem;

    p:nth-child(2) {
      margin: 2rem 0;
    }

    p {
      font-size: 1.5rem;
      font-weight: 300;
      max-width: 30rem;

      span {
        color: #0C6DA8;
        font-weight: 600;
      }
    }
  }

  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40rem;
    img:first-child {
      width: 4rem;
    }

    img:nth-child(2) {
      width: 6rem;
    }

    img:nth-child(3) {
      width: 5rem;
    }

    img:nth-child(4) {
      width: 4.5rem;
    }

    img:last-child {
      width: 12rem;
    }
  }
}

@media (max-width: 1024px) {
  .content-demo {
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;

    .info {
      padding: 0;
      
      .flex {
        flex-direction: column;
      }

      &-header {
        text-align: center;

        &__logo {
          margin: 0;
        }
      }

      &-content {
        margin: auto;
        text-align: center;
    
        p {
          margin: auto;
        }

        p:nth-child(2) {
          margin: 2rem auto;
        }
      }

      .responsive {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .cards {
        margin: 2.5rem auto;
        max-width: 30rem;
        align-items: center;
        justify-content: space-around;
        
        img:last-child {
          margin-top: 2rem;
        }

        .info .cards img:not(:first-child):not(:last-child):nth-child(even) {
          margin: 0 !important;
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .content-demo {
    max-width: 90em;
    background-position: 100% 65%, 100% 10%;
    background-size: 30%, 35%;
  }
}

@media (min-width: 3200px) {
  .content-demo {
    background-position: 100% 50%, 100% 10%;
    background-size: 35%, 40%;
  }
}
