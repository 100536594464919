@import './../../../vars.scss';

.gradient {
  background-color: $blue-espiral;
  background-image: linear-gradient(110deg, $blue-espiral 70%, $purple-espiral 115%);
}

.flex {
  display: flex;
}

.section-download-app {
  box-sizing: border-box;
  width: 100vw;
  background-image: url('/img/011_celulares.png');
  background-position: left top 4rem;
  background-repeat: no-repeat;
  background-size: 57%;
  justify-content: flex-end;
  align-items: center;

  .content {
    width: 40%;
    color: white;

    &-title {
      max-width: 35rem;

      h1 {
        font-weight: 600;
        font-size: 2.5rem;
        margin: 0;
      }

      span {
        font-weight: 300;
      }
    }

    &-stores {
      margin: 3.5rem 0;
      img {
        width: 10rem;
        &:first-child {
          margin-right: 3rem;
        }
      }
    }

    p {
      font-size: 1.5rem;
      max-width: 35rem;

      span {
        font-weight: 600;
      }
    }

    &-description {
      font-weight: 300;
    }

    &-comision {
      font-weight: 300;
      margin: 2.5rem 0;
      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }

    &-payments {
      font-weight: 300;
      .table {
        justify-content: space-between;
        max-width: 40rem;
      }

      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .gradient {
    background-image: linear-gradient(125deg, $blue-espiral 70%, $purple-espiral 145%);
  }

  .section-download-app {
    background-image: none;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    .content {
      width: 80%;
      color: white;
      text-align: center;

      &-title {
        h1 {
          margin: 0;
        }
      }

      .mob-center {
        text-align: center;
      }

      &-stores {
        justify-content: space-evenly;
      }

      &-payments {
        .table {
          padding-top: 2rem;
        }
      }
    }
  }
}

@media (min-width: 425px) and (max-width: 1024px) {
  .gradient {
    .section-download-app {
      background-position: center bottom -84rem;
      .content {
        // margin-top: 6rem;
        width: 100%;
        text-align: center;

        &-title {
          margin: 0 auto 2rem;
        }

        &-stores {
          margin: auto;
          max-width: 25rem;
        }

        &-description {
          max-width: 50rem;
          text-align: center;
          margin: auto;

          p {
            margin: 2rem auto;
          }
        }

        &-comision {
          max-width: 50rem;
          margin: 2rem auto;

          p {
            text-align: center;
            margin: auto;
          }
        }

        &-payments {
          max-width: 50rem;
          margin: 2rem auto;
          
          .table {
            text-align: center;
            margin: auto;
          }

          p {
            text-align: center;
            margin: auto;
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .gradient {
    .section-download-app {
      background-position: left bottom -10rem;
      background-size: 50%;
      padding: 2rem;
      .content {
        &-title {
          h1 {
            font-size: 2rem;
          }
        }

        p {
          font-size: 1rem;
        }

        &-comision {
          h1 {
            font-size: 2rem;
          }
        }

        &-payments {
          .table {
            max-width: 30rem;
          }
          h1 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .gradient {
    .section-download-app {
      background-position: left bottom -13rem;
      background-size: 50%;
      min-height: 90vh;
      .content {
        width: 45;
      }
    }
  }
}

@media (min-width: 1680px) {
  .gradient {
    .section-download-app {
      background-position: left bottom -13rem;
      background-size: 52%;
      align-items: center;
      min-height: 98vh;
      .content {
        &-title {
          h1 {
            font-size: 2.5rem;
          }
        }

        p {
          font-size: 1.5rem;
        }

        &-comision {
          h1 {
            font-size: 2.5rem;
          }
        }

        &-payments {
          .table {
            max-width: 40rem;
          }
          h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media (min-width: 2256px) {
  .gradient {
    .section-download-app {
      background-position: left center;
      background-size: 60%;
    }
  }
}
