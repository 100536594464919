@import './../../../vars.scss';

.wl-gradient {
    background-color: #00a5e9;
    background-image: linear-gradient(110deg, #0C6DA8 75%, #9C0AE8 115%);
}

.content-wl {
    // width: 100vw;
    background-image: url('/img/017_hand-8.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 42%;
    position: relative;
    color: white;
}

.wl {
    padding: 9rem 0rem;

    &-header {
        line-height: .5;
        align-items: center;

        &__title {
            font-size: 1.7rem;

            h1 {
                font-weight: 600;
                color: white;
            }

            h2 {
                font-weight: 300;
                color: white;
            }
        }
  }

    &-content {
        max-width: 40rem;
        margin-top: 4rem;

        p {
            font-size: 1.5rem;
            font-weight: 300;
            margin: 2rem 0;
        }

        .os {
            margin-top: 4rem;
        }
    }
}

@media (max-width: 1024px) {
    .content-wl {
        // min-height: 80vh;
        // max-height: 90vh;
        background-image: none;
        background-size: 150%;
        background-position: bottom -28rem right;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .wl {
        &-header {
            line-height: 1;
            align-items: center;
            text-align: center;
    
            &__title {
                font-size: 1.7rem;
    
                h1 {
                    font-weight: 600;
                    color: white;
                }
    
                h2 {
                    font-weight: 300;
                    color: white;
                }
            }
      }
    
        &-content {
            max-width: 40rem;
            padding: 0 2rem;
            margin: 0;
            text-align: center;
    
            p {
                font-size: 1.5rem;
                font-weight: 300;
                margin: 2rem 0;
            }
    
            .os {
                margin-top: 4rem;
                text-align: center;
            }
        }
    }
}
