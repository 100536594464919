@import './vars.scss';

//  ██████╗ ██████╗ ██╗      ██████╗ ██████╗ ███████╗     ██████╗██╗      █████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗██╔════╝    ██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
// ██║     ██║   ██║██║     ██║   ██║██████╔╝███████╗    ██║     ██║     ███████║███████╗███████╗
// ██║     ██║   ██║██║     ██║   ██║██╔══██╗╚════██║    ██║     ██║     ██╔══██║╚════██║╚════██║
// ╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║███████║    ╚██████╗███████╗██║  ██║███████║███████║
//  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝     ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝
                                                                                              
.back-blue-dark-beluga {
  background-color: $blue-dark-beluga !important;
}

.text-blue-dark-beluga {
  color: $blue-dark-beluga !important;
}

.text-blue-light-beluga {
  color: $blue-light-beluga !important;
}

.text-white {
  color: white;
}

// ████████╗███████╗██╗  ██╗████████╗     ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ 
// ╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝    ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ 
//    ██║   █████╗   ╚███╔╝    ██║       ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗
//    ██║   ██╔══╝   ██╔██╗    ██║       ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║
//    ██║   ███████╗██╔╝ ██╗   ██║       ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝
//    ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝        ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝ 

.light {
  font-weight: 200 !important;
}

.normal {
  font-weight: 400 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

// ██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║███████╗
// ██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║╚════██║
// ██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║███████║
// ╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝
        

.MuiButton-outlined {
  border: 5px solid #fff !important;
  padding: 10px 50px !important;
}

.MuiButton-root {
  color: #fff !important;
  padding: 6px 16px;
  font-size: 1.5rem !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 20px !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.btn {
  display: inline-block;
  padding: .3em 1.1em;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  cursor: pointer;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
}

.btn-blue-dark {
  background-color: $blue-dark-beluga;
  color: white;
  transition: all 250ms !important;

  &.btn-blue-dark:hover {
    background-color: white;
    color: $blue-dark-beluga;
  }
}

.btn-header {
  font-size: 1.5em;
}
