@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,500;0,600;0,700;1,300&display=swap');
@import '/css/bootstrap/css/bootstrap-grid.min.css';
@import './vars.scss';

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: 'Montserrat', sans-serif;
  -moz-osx-font-smoothing: 'Montserrat', sans-serif;
}

section {
  max-width: 100rem;
  margin: auto;
}

.w-100 {
  width: 100%;
}

@media (min-width: $xs) {
  html, body {
    font-size: 9px;
  }
}

@media (min-width: $sm) {
  html, body {
    font-size: 13px;
  }
}

@media (min-width: $md) {
  html, body {
    font-size: 16px;
  }
}

@media (min-width: $lg) {
  html, body {
    font-size: 16px;
  }
}

@media (min-width: $xl) {
  html, body {
    font-size: 20px;
  }
}
