@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,500;0,600;0,700;1,300&display=swap);
@import url(/css/bootstrap/css/bootstrap-grid.min.css);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: 'Montserrat', sans-serif;
  -moz-osx-font-smoothing: 'Montserrat', sans-serif; }

section {
  max-width: 100rem;
  margin: auto; }

.w-100 {
  width: 100%; }

@media (min-width: 0px) {
  html, body {
    font-size: 9px; } }

@media (min-width: 600px) {
  html, body {
    font-size: 13px; } }

@media (min-width: 960px) {
  html, body {
    font-size: 16px; } }

@media (min-width: 1280px) {
  html, body {
    font-size: 16px; } }

@media (min-width: 1920px) {
  html, body {
    font-size: 20px; } }

.back-blue-dark-beluga {
  background-color: #00373f !important; }

.text-blue-dark-beluga {
  color: #00373f !important; }

.text-blue-light-beluga {
  color: #06a0b5 !important; }

.text-white {
  color: white; }

.light {
  font-weight: 200 !important; }

.normal {
  font-weight: 400 !important; }

.semi-bold {
  font-weight: 500 !important; }

.bold {
  font-weight: 700 !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.position-relative {
  position: relative; }

.MuiButton-outlined {
  border: 5px solid #fff !important;
  padding: 10px 50px !important; }

.MuiButton-root {
  color: #fff !important;
  padding: 6px 16px;
  font-size: 1.5rem !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 20px !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase; }

.btn {
  display: inline-block;
  padding: .3em 1.1em;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px; }

.btn-blue-dark {
  background-color: #00373f;
  color: white;
  transition: all 250ms !important; }
  .btn-blue-dark.btn-blue-dark:hover {
    background-color: white;
    color: #00373f; }

.btn-header {
  font-size: 1.5em; }

.MuiFab-root {
  box-shadow: none !important; }

.MuiDrawer-paperAnchorRight {
  background-color: #0C6DA8 !important; }

.container-slider-menu .MuiAppBar-colorPrimary {
  background-color: #0C6DA8 !important; }
  .container-slider-menu .MuiAppBar-colorPrimary .MuiToolbar-root {
    padding: 15px 0 20px 0;
    min-height: auto !important; }

.container-slider-menu .MuiPaper-elevation4 {
  box-shadow: none !important; }

.container-slider-menu .image-logo {
  height: 3.1em; }

.container-menu {
  padding: 2rem 0;
  width: 23rem;
  max-width: 25rem;
  text-align: center; }
  .container-menu .container-logo {
    height: 3.5em;
    margin-bottom: 1.5rem; }
  .container-menu .container-bts {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 6px; }
  .container-menu .container-btn-flags .icon-flag {
    width: 1.7em; }
  .container-menu.mobile {
    font-size: 16px; }
    .container-menu.mobile .container-bts {
      display: block; }
      .container-menu.mobile .container-bts .btn-menu {
        margin: 0; }
        .container-menu.mobile .container-bts .btn-menu > .MuiButton-root {
          width: 100%; }
    .container-menu.mobile .container-btn-flags {
      text-align: center; }
      .container-menu.mobile .container-btn-flags .icon-flag {
        width: 3.7em; }

.btn-icon-menu {
  background-color: transparent !important;
  position: fixed !important;
  right: 3rem;
  top: 3rem;
  z-index: 10;
  width: 4em !important;
  height: 4em !important;
  min-height: auto !important;
  min-width: auto !important;
  padding: .7em !important;
  box-sizing: content-box !important; }

.icon-show-menu {
  width: 100%;
  max-width: 2rem; }

@media (max-width: 1024px) {
  .container-menu {
    display: block;
    padding: .5em; } }

@media (max-width: 600px) {
  .container-menu {
    padding: 2.5rem 2rem 2rem; }
  .btn-icon-menu {
    top: 3%;
    width: 3em;
    height: 3em; } }

ul {
  margin: 0;
  padding: 0; }

li {
  width: 100%;
  padding: 1rem 0 1rem 2rem;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
  background-size: 225%;
  transition: all .4s; }
  li:hover, li:active {
    background-position: 100%; }

.image-logo {
  -webkit-animation: scaleIn .4s ease-in-out;
          animation: scaleIn .4s ease-in-out; }

.nav {
  overflow: scroll; }
  .nav__list {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
    -webkit-animation: fadeIn 1s ease-in-out;
            animation: fadeIn 1s ease-in-out; }
  .nav__item {
    width: 100%;
    margin: .5rem 0; }
    .nav__item:hover a {
      color: #14ABF7; }
  .nav__link:link, .nav__link:visited {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; }
  .nav__link:hover, .nav__link:active {
    background-position: 100%;
    color: #14ABF7;
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem); }

.buttons {
  margin-top: 2rem; }
  .buttons .btn-menu {
    color: #14ABF7 !important;
    font-size: 1rem !important;
    font-weight: 500;
    border: 5px solid #14ABF7 !important;
    padding: 0.1em 2.3em;
    border-radius: 10px;
    transition: all 250ms; }
    .buttons .btn-menu:hover {
      border: 5px solid white !important;
      background-color: white !important;
      color: #14ABF7; }
  .buttons .call {
    margin-top: 2rem; }

a:visited, a:link {
  text-decoration: none; }

@-webkit-keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.section-header {
  background-image: url("/img/000_BelugaMPOS-3.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; }

.section-background {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .section-background a {
    color: black; }
  .section-background .header__logo {
    width: 100%;
    max-width: 20rem;
    display: block;
    position: absolute;
    top: 3rem;
    left: 3rem; }
  .section-background .header__language {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .section-background .header__language p {
      margin-top: 0;
      cursor: pointer; }
    .section-background .header__language p:nth-child(2) {
      border-left: 1px solid black;
      margin-left: 15px;
      padding-left: 15px; }
    .section-background .header__language .flag-lang {
      width: 50px; }
  .section-background .header__phone {
    margin-top: 0;
    font-size: 1.2rem;
    font-weight: 600;
    position: absolute;
    top: 3.6rem;
    right: 8rem; }
    .section-background .header__phone a {
      color: #9C0AE8; }
  .section-background .header__button {
    margin-top: 6rem; }
  .section-background .btn-blue {
    color: white !important;
    font-size: 1.3em;
    font-weight: 500;
    border: 5px solid white !important;
    padding: 0.1em 2.3em;
    border-radius: 10px;
    transition: all 250ms;
    margin: 2rem 0; }
    .section-background .btn-blue:hover {
      border: 5px solid #0C6DA8 !important;
      color: #0C6DA8 !important; }
  .section-background .section-text-center {
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    color: #0C6DA8;
    font-size: 5.3em;
    line-height: 0; }
    .section-background .section-text-center .payments {
      font-size: .40em; }

@media (max-width: 320px) {
  .section-background .header__logo {
    max-width: 15rem; }
  .section-background .header__phone {
    top: 2rem !important; } }

@media (max-width: 1024px) {
  .section-header {
    background-image: url("/img/000_BelugaMPOS-3_800.jpg");
    background-position: center center;
    background-size: cover; }
    .section-header .header__logo {
      top: 2.5rem;
      left: 2rem; }
    .section-header .header__phone {
      font-size: 1.2rem;
      font-weight: 600;
      position: absolute;
      top: 3.2rem;
      right: 8rem; }
    .section-header .header__button {
      margin: 6rem 0 0 -2.5rem; }
    .section-header .section-text-center {
      margin-left: -2.5rem;
      color: white;
      font-size: 3.3em;
      line-height: 0; }
      .section-header .section-text-center .payments {
        text-align: left;
        font-size: .40em; } }

.trade-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 5em 0; }

.content-trademark {
  text-align: center; }
  .content-trademark h1 {
    color: #0C6DA8; }
  .content-trademark .cards {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center; }
    .content-trademark .cards img {
      width: 6rem; }
    .content-trademark .cards-cert img:first-child,
    .content-trademark .cards-cert img:nth-child(2),
    .content-trademark .cards-cert img:nth-child(10) {
      width: 4rem; }
    .content-trademark .cards-cert img:nth-child(8),
    .content-trademark .cards-cert img:nth-child(9) {
      width: 5rem; }
    .content-trademark .cards-cert img:nth-child(6) {
      width: 8rem; }
    .content-trademark .cards-cert img:nth-child(5),
    .content-trademark .cards-cert img:nth-child(7) {
      width: 10rem; }
  .content-trademark h1 {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 2rem; }
    .content-trademark h1 span {
      font-weight: 300; }

@media (max-width: 1024px) {
  .trade-container {
    background-image: none; }
    .trade-container .content-trademark .cards {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      padding: 0 1.2rem; }
      .trade-container .content-trademark .cards img {
        margin: 2rem 0; }
      .trade-container .content-trademark .cards img:nth-child(even) {
        margin: 0 2rem; }
      .trade-container .content-trademark .cards img:nth-child(4) {
        margin-left: 1.8rem; } }

@media (min-width: 768px) {
  .trade-container .content-trademark .cards img:not(:first-child):nth-child(even) {
    margin: 2rem 3.5rem; } }

.content-demo {
  min-height: 85vh;
  background-image: url("/img/010_ilustracion_1-8.png"), url("/img/011_ilustracion-8.png");
  background-repeat: no-repeat;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.info-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  line-height: .5;
  -webkit-align-items: center;
          align-items: center; }
  .info-header__logo {
    margin-right: 4rem; }
    .info-header__logo img {
      width: 7rem; }
  .info-header__title {
    font-size: 1.7rem; }
    .info-header__title h1 {
      color: #9C0AE8;
      font-weight: 600; }
    .info-header__title h2 {
      color: #9C0AE8;
      font-weight: 400; }

.info-content {
  margin: 2rem 0 0 11rem; }
  .info-content p:nth-child(2) {
    margin: 2rem 0; }
  .info-content p {
    font-size: 1.5rem;
    font-weight: 300;
    max-width: 30rem; }
    .info-content p span {
      color: #0C6DA8;
      font-weight: 600; }

.info .cards {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 40rem; }
  .info .cards img:first-child {
    width: 4rem; }
  .info .cards img:nth-child(2) {
    width: 6rem; }
  .info .cards img:nth-child(3) {
    width: 5rem; }
  .info .cards img:nth-child(4) {
    width: 4.5rem; }
  .info .cards img:last-child {
    width: 12rem; }

@media (max-width: 1024px) {
  .content-demo {
    background-image: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 4rem 0; }
    .content-demo .info {
      padding: 0; }
      .content-demo .info .flex {
        -webkit-flex-direction: column;
                flex-direction: column; }
      .content-demo .info-header {
        text-align: center; }
        .content-demo .info-header__logo {
          margin: 0; }
      .content-demo .info-content {
        margin: auto;
        text-align: center; }
        .content-demo .info-content p {
          margin: auto; }
        .content-demo .info-content p:nth-child(2) {
          margin: 2rem auto; }
      .content-demo .info .responsive {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: center;
                justify-content: center; }
      .content-demo .info .cards {
        margin: 2.5rem auto;
        max-width: 30rem;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-around;
                justify-content: space-around; }
        .content-demo .info .cards img:last-child {
          margin-top: 2rem; }
        .content-demo .info .cards .info .cards img:not(:first-child):not(:last-child):nth-child(even) {
          margin: 0 !important; } }

@media (min-width: 1025px) {
  .content-demo {
    max-width: 90em;
    background-position: 100% 65%, 100% 10%;
    background-size: 30%, 35%; } }

@media (min-width: 3200px) {
  .content-demo {
    background-position: 100% 50%, 100% 10%;
    background-size: 35%, 40%; } }

.gradient {
  background-color: #0C6DA8;
  background-image: linear-gradient(110deg, #0C6DA8 70%, #9C0AE8 115%); }

.flex {
  display: -webkit-flex;
  display: flex; }

.section-download-app {
  box-sizing: border-box;
  width: 100vw;
  background-image: url("/img/011_celulares.png");
  background-position: left top 4rem;
  background-repeat: no-repeat;
  background-size: 57%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }
  .section-download-app .content {
    width: 40%;
    color: white; }
    .section-download-app .content-title {
      max-width: 35rem; }
      .section-download-app .content-title h1 {
        font-weight: 600;
        font-size: 2.5rem;
        margin: 0; }
      .section-download-app .content-title span {
        font-weight: 300; }
    .section-download-app .content-stores {
      margin: 3.5rem 0; }
      .section-download-app .content-stores img {
        width: 10rem; }
        .section-download-app .content-stores img:first-child {
          margin-right: 3rem; }
    .section-download-app .content p {
      font-size: 1.5rem;
      max-width: 35rem; }
      .section-download-app .content p span {
        font-weight: 600; }
    .section-download-app .content-description {
      font-weight: 300; }
    .section-download-app .content-comision {
      font-weight: 300;
      margin: 2.5rem 0; }
      .section-download-app .content-comision p {
        padding: 0;
        margin: 0; }
      .section-download-app .content-comision h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0; }
    .section-download-app .content-payments {
      font-weight: 300; }
      .section-download-app .content-payments .table {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        max-width: 40rem; }
      .section-download-app .content-payments p {
        padding: 0;
        margin: 0; }
      .section-download-app .content-payments h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0; }

@media (max-width: 1024px) {
  .gradient {
    background-image: linear-gradient(125deg, #0C6DA8 70%, #9C0AE8 145%); }
  .section-download-app {
    background-image: none;
    min-height: 80vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 4rem 0; }
    .section-download-app .content {
      width: 80%;
      color: white;
      text-align: center; }
      .section-download-app .content-title h1 {
        margin: 0; }
      .section-download-app .content .mob-center {
        text-align: center; }
      .section-download-app .content-stores {
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly; }
      .section-download-app .content-payments .table {
        padding-top: 2rem; } }

@media (min-width: 425px) and (max-width: 1024px) {
  .gradient .section-download-app {
    background-position: center bottom -84rem; }
    .gradient .section-download-app .content {
      width: 100%;
      text-align: center; }
      .gradient .section-download-app .content-title {
        margin: 0 auto 2rem; }
      .gradient .section-download-app .content-stores {
        margin: auto;
        max-width: 25rem; }
      .gradient .section-download-app .content-description {
        max-width: 50rem;
        text-align: center;
        margin: auto; }
        .gradient .section-download-app .content-description p {
          margin: 2rem auto; }
      .gradient .section-download-app .content-comision {
        max-width: 50rem;
        margin: 2rem auto; }
        .gradient .section-download-app .content-comision p {
          text-align: center;
          margin: auto; }
      .gradient .section-download-app .content-payments {
        max-width: 50rem;
        margin: 2rem auto; }
        .gradient .section-download-app .content-payments .table {
          text-align: center;
          margin: auto; }
        .gradient .section-download-app .content-payments p {
          text-align: center;
          margin: auto; } }

@media (min-width: 1280px) {
  .gradient .section-download-app {
    background-position: left bottom -10rem;
    background-size: 50%;
    padding: 2rem; }
    .gradient .section-download-app .content-title h1 {
      font-size: 2rem; }
    .gradient .section-download-app .content p {
      font-size: 1rem; }
    .gradient .section-download-app .content-comision h1 {
      font-size: 2rem; }
    .gradient .section-download-app .content-payments .table {
      max-width: 30rem; }
    .gradient .section-download-app .content-payments h1 {
      font-size: 1.5rem; } }

@media (min-width: 1366px) {
  .gradient .section-download-app {
    background-position: left bottom -13rem;
    background-size: 50%;
    min-height: 90vh; }
    .gradient .section-download-app .content {
      width: 45; } }

@media (min-width: 1680px) {
  .gradient .section-download-app {
    background-position: left bottom -13rem;
    background-size: 52%;
    -webkit-align-items: center;
            align-items: center;
    min-height: 98vh; }
    .gradient .section-download-app .content-title h1 {
      font-size: 2.5rem; }
    .gradient .section-download-app .content p {
      font-size: 1.5rem; }
    .gradient .section-download-app .content-comision h1 {
      font-size: 2.5rem; }
    .gradient .section-download-app .content-payments .table {
      max-width: 40rem; }
    .gradient .section-download-app .content-payments h1 {
      font-size: 2rem; } }

@media (min-width: 2256px) {
  .gradient .section-download-app {
    background-position: left center;
    background-size: 60%; } }

.flex {
  display: -webkit-flex;
  display: flex; }

.section-withBeluga {
  max-height: 100vh;
  min-height: 90vh;
  background-image: url("/img/013_dash.png"), url("/img/016_Circulo.svg");
  background-position: -60% 50%, 20% 0%;
  background-repeat: no-repeat;
  background-size: 65%, 30%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }
  .section-withBeluga .content {
    width: 50rem; }
    .section-withBeluga .content-left {
      width: 10rem;
      padding-top: 2rem; }
      .section-withBeluga .content-left img {
        max-width: 7rem;
        width: 100%; }
    .section-withBeluga .content-title {
      max-width: 35rem; }
      .section-withBeluga .content-title h1 {
        font-weight: 300;
        font-size: 2.5rem;
        color: #9C0AE8; }
      .section-withBeluga .content-title span {
        font-weight: 600; }
    .section-withBeluga .content p {
      font-size: 1.5rem;
      max-width: 38rem; }
    .section-withBeluga .content-description {
      font-weight: 300; }
    .section-withBeluga .content-comision {
      font-weight: 300;
      margin: 2.5rem 0; }
      .section-withBeluga .content-comision p {
        padding: 0;
        margin: 0; }
      .section-withBeluga .content-comision h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0; }
    .section-withBeluga .content-payments {
      font-weight: 300; }
      .section-withBeluga .content-payments .table {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        max-width: 40rem; }
      .section-withBeluga .content-payments p {
        padding: 0;
        margin: 0; }
      .section-withBeluga .content-payments h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0; }

@media (max-width: 1300px) {
  .responsive {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
  .section-withBeluga {
    padding: 0 4rem;
    background-image: none;
    background-position: bottom -10rem center, bottom 1rem right 2rem;
    -webkit-justify-content: center;
            justify-content: center; }
    .section-withBeluga .content-left {
      text-align: center; }
      .section-withBeluga .content-left img {
        width: 7rem; }
    .section-withBeluga .content-title {
      text-align: center; } }

@media (min-width: 2560px) {
  .section-withBeluga {
    background-position: -60% 50%, 25% 10%; } }

@media (min-width: 3200px) {
  .section-withBeluga {
    min-height: 70vh;
    background-position: -60% 50%, 25% 10%; } }

.content-pos {
  width: 100vw;
  position: relative; }

.abs {
  position: absolute; }

.tag {
  text-align: right;
  line-height: .5;
  font-size: 1.2rem;
  color: #0c6da8; }

.pos-header {
  line-height: .5;
  -webkit-align-items: center;
          align-items: center; }
  .pos-header__title {
    font-size: 1.7rem; }
    .pos-header__title h1 {
      font-weight: 600;
      color: #9C0AE8; }
    .pos-header__title h2 {
      font-weight: 400;
      color: #9C0AE8; }

.pos-content {
  max-width: 35rem; }
  .pos-content p {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 2rem 0; }
  .pos-content .os {
    display: -webkit-flex;
    display: flex;
    max-width: 25em;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    .pos-content .os .microsoft {
      width: 12rem;
      height: 2.8rem; }

.pos .mobile-terminal {
  overflow: hidden;
  position: relative;
  max-width: 80vw;
  min-height: 25rem; }
  .pos .mobile-terminal img {
    width: 140%;
    margin-left: 5rem; }
  .pos .mobile-terminal .pinpad-mob {
    top: 0;
    left: 0; }
  .pos .mobile-terminal .printer-mob {
    bottom: 0;
    right: 0; }

@media (max-width: 1024px) {
  .pos {
    -webkit-justify-content: center;
            justify-content: center; }
  .tag {
    text-align: right;
    line-height: 1;
    font-size: 1.2rem;
    color: #9C0AE8; }
  .pinpad {
    display: none; }
  .printer {
    display: none; }
  .pos {
    padding: 8rem 4rem 0; }
    .pos-header {
      text-align: center;
      line-height: .5;
      -webkit-align-items: center;
              align-items: center; }
    .pos-content .os {
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      max-width: 100%;
      margin: 2.5em 0; }
      .pos-content .os img {
        width: 8%;
        height: 8%; }
        .pos-content .os img:last-child {
          width: 40%;
          height: 40%; }
  .mobile-terminal {
    max-width: 80vw;
    min-height: 25rem;
    overflow: hidden;
    margin: auto;
    position: relative; }
    .mobile-terminal img {
      width: 40rem;
      max-width: 40rem;
      margin: 0 0 0 7rem;
      display: block; }
    .mobile-terminal .pinpad-mob {
      text-align: left;
      bottom: 5em;
      left: 0; }
    .mobile-terminal .printer-mob {
      bottom: 0;
      right: 0; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .content-pos .mobile-terminal {
    max-width: 80vw;
    min-height: 30rem;
    overflow: hidden;
    margin: auto; }
    .content-pos .mobile-terminal img {
      width: 100%;
      margin-left: 5rem;
      max-width: 429px; }
    .content-pos .mobile-terminal .pinpad-mob {
      top: 20rem;
      left: 1rem; }
    .content-pos .mobile-terminal .printer-mob {
      top: 0;
      right: 0; } }

@media (min-width: 1025px) {
  .content-pos {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .mobile-terminal {
    max-width: 50rem;
    overflow: hidden;
    height: 41.5rem;
    position: relative; }
    .mobile-terminal img {
      width: 160%; }
    .mobile-terminal .pinpad-mob {
      bottom: 5.5rem;
      left: 2rem; }
    .mobile-terminal .printer-mob {
      bottom: -.5rem;
      right: 14rem; } }

@media (min-width: 1025px) and (max-width: 1550px) {
  .content-pos {
    display: -webkit-flex;
    display: flex;
    min-height: 65vw;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .mobile-terminal {
    max-width: 40rem;
    overflow: hidden;
    height: 35rem;
    position: relative; }
    .mobile-terminal img {
      width: 160%; }
    .mobile-terminal .pinpad-mob {
      bottom: 5rem;
      left: 2rem; }
    .mobile-terminal .printer-mob {
      bottom: -.5rem;
      right: 8rem; } }

@media (min-width: 1600px) {
  .content-pos {
    display: -webkit-flex;
    display: flex;
    min-height: 80vh;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; } }

@media (min-width: 3200px) {
  .content-pos {
    height: 70vh;
    min-height: 70vh; } }

.wl-gradient {
  background-color: #00a5e9;
  background-image: linear-gradient(110deg, #0C6DA8 75%, #9C0AE8 115%); }

.content-wl {
  background-image: url("/img/017_hand-8.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 42%;
  position: relative;
  color: white; }

.wl {
  padding: 9rem 0rem; }
  .wl-header {
    line-height: .5;
    -webkit-align-items: center;
            align-items: center; }
    .wl-header__title {
      font-size: 1.7rem; }
      .wl-header__title h1 {
        font-weight: 600;
        color: white; }
      .wl-header__title h2 {
        font-weight: 300;
        color: white; }
  .wl-content {
    max-width: 40rem;
    margin-top: 4rem; }
    .wl-content p {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 2rem 0; }
    .wl-content .os {
      margin-top: 4rem; }

@media (max-width: 1024px) {
  .content-wl {
    background-image: none;
    background-size: 150%;
    background-position: bottom -28rem right;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .wl-header {
    line-height: 1;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .wl-header__title {
      font-size: 1.7rem; }
      .wl-header__title h1 {
        font-weight: 600;
        color: white; }
      .wl-header__title h2 {
        font-weight: 300;
        color: white; }
  .wl-content {
    max-width: 40rem;
    padding: 0 2rem;
    margin: 0;
    text-align: center; }
    .wl-content p {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 2rem 0; }
    .wl-content .os {
      margin-top: 4rem;
      text-align: center; } }

.footer {
  border-top: solid 1px #00aad1;
  color: white;
  margin-top: 1px;
  padding: 1.2em 0;
  font-size: .8em; }
  .footer-vert-gap {
    margin: 0 0 30px; }
  .footer-mail {
    color: white;
    font-weight: 200;
    font-size: 1.3em;
    margin: 1rem 0; }
  .footer-address {
    color: white;
    font-weight: 200;
    margin-bottom: 1rem; }
  .footer-follow {
    color: white;
    font-weight: 300;
    font-size: 1.7em;
    margin-bottom: 1rem; }
  .footer-social {
    width: 3em;
    height: 3em;
    margin: 5px 15px 0 0; }

footer {
  padding-top: 3em;
  background-color: #0C6DA8; }
  footer a {
    color: white; }
  footer .link {
    font-style: inherit;
    color: inherit;
    font-size: 2em;
    font-weight: 500;
    margin: 0; }
  footer .text {
    font-style: inherit;
    color: inherit;
    font-size: 1.7em;
    font-weight: 200; }
  footer .container-footer-info {
    text-align: center; }

.image-logo {
  width: 13.5em; }

.center {
  text-align: center; }

#txt-white {
  color: white;
  font-weight: 200; }

.list {
  margin: .5em 0 .3em 0 !important;
  padding-left: .7em !important; }
  .list-head {
    border-left: solid #06a0b5 2px; }

@media only screen and (min-width: 435px) {
  .center {
    text-align: left; } }

@media only screen and (max-width: 768px) {
  .footer-mail {
    padding: 10px 0;
    color: white;
    font-weight: 200;
    font-size: 1.5em; }
  .footer-social {
    width: 2.3em;
    height: 2.3em; } }

.container-contact {
  background-color: #0C6DA8; }
  .container-contact .container-form {
    z-index: 3;
    position: relative;
    background-color: #0C6DA8;
    max-width: 1048px;
    width: calc(100% - 4em);
    margin: auto;
    border-radius: 20px 20px 0 0;
    padding: 2em;
    box-shadow: -14px -14px 19px -9px rgba(70, 70, 70, 0.3), 14px -14px 19px -9px rgba(70, 70, 70, 0.3); }
  .container-contact .container-contact-info {
    display: grid;
    grid-template-columns: calc(50% - 3em) 50%;
    grid-column-gap: 3em; }
    .container-contact .container-contact-info .MuiInput-underline:after {
      border-bottom: 2px solid #14ABF7; }
    .container-contact .container-contact-info .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid #14ABF7; }
    .container-contact .container-contact-info .MuiInput-underline:before {
      border-bottom: 1px solid #14ABF7; }
    .container-contact .container-contact-info .MuiFormLabel-root, .container-contact .container-contact-info .MuiFormLabel-root.Mui-focused {
      color: white;
      font-weight: 300; }
    .container-contact .container-contact-info .MuiInputBase-input {
      padding: 6px 0 15px; }
    .container-contact .container-contact-info input, .container-contact .container-contact-info textarea {
      color: white;
      font-weight: 300; }
    .container-contact .container-contact-info .title-contact {
      color: #14ABF7;
      text-align: left;
      font-size: 1.7em;
      font-weight: 300; }
    .container-contact .container-contact-info .talkUs {
      font-weight: 200;
      font-size: 1.9em;
      letter-spacing: 2px;
      line-height: 1em;
      color: white; }
    .container-contact .container-contact-info .center {
      text-align: left;
      padding-top: 14em; }
    .container-contact .container-contact-info .assessor {
      color: white;
      font-size: 1.5em;
      font-weight: 200; }
    .container-contact .container-contact-info .btn-send {
      color: #14ABF7 !important;
      font-size: 1.3em;
      font-weight: 500;
      border: 5px solid #14ABF7 !important;
      padding: 0.1em 2.3em;
      border-radius: 10px;
      transition: all 250ms; }
      .container-contact .container-contact-info .btn-send:hover {
        border: 5px solid white !important;
        background-color: white;
        color: #14ABF7; }
  .container-contact .lower {
    text-transform: lowercase !important; }
  .container-contact .container-footer {
    background-color: #0C6DA8;
    z-index: 1;
    position: relative; }

.MuiSnackbar-root .MuiTypography-root {
  background-color: white;
  color: #0C6DA8; }

@media only screen and (min-width: 426px) {
  .container-contact .container-contact-info .center {
    padding-top: 11em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; } }

@media only screen and (min-width: 1024px) {
  .container-contact .container-contact-info .center {
    padding-top: 9.5em; } }

@media only screen and (min-width: 1920px) {
  .container-contact .container-contact-info .center {
    padding-top: 8em; } }

.section-get-espiral {
  background-image: url("/img/020_bannerfinal.jpg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 75vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .section-get-espiral .espiral-content {
    text-align: center; }
    .section-get-espiral .espiral-content h1 {
      color: #0C6DA8;
      font-size: 2.5rem; }
      .section-get-espiral .espiral-content h1 span:first-child {
        font-weight: 300; }
      .section-get-espiral .espiral-content h1 span:last-child {
        color: #9C0AE8; }
    .section-get-espiral .espiral-content .header__button {
      margin: 3.5rem 0; }
    .section-get-espiral .espiral-content .btn-send {
      color: white !important;
      font-size: 1.3em;
      font-weight: 500;
      border: 5px solid white !important;
      padding: 0.1em 2.3em;
      border-radius: 10px;
      transition: all 250ms;
      margin: 2rem 0; }
      .section-get-espiral .espiral-content .btn-send:hover {
        border: 5px solid white !important;
        color: #14ABF7; }
    .section-get-espiral .espiral-content p {
      margin: 2rem auto;
      font-size: 2rem;
      font-weight: 300;
      color: #0C6DA8; }
      .section-get-espiral .espiral-content p span {
        font-weight: 400; }

.container-terms-conditions .terms-conditions {
  margin: 6em 0; }

