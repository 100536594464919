@import './../../../vars.scss';

.flex {
  display: flex;
}

.section-withBeluga {
  max-height: 100vh;
  min-height: 90vh;
  background-image: url('/img/013_dash.png'), url('/img/016_Circulo.svg');
  background-position: -60% 50%, 20% 0%;
  background-repeat: no-repeat;
  background-size: 65%, 30%;
  justify-content: flex-end;
  align-items: center;

  .content {
    width: 50rem;

    &-left {
        width: 10rem;
        padding-top: 2rem;

        img {
            max-width: 7rem;
            width: 100%;
        }
    }
      
    &-title {
      max-width: 35rem;

      h1 {
        font-weight: 300;
        font-size: 2.5rem;
        color: #9C0AE8;
      }

      span {
        font-weight: 600;
      }
    }

    p {
      font-size: 1.5rem;
      max-width: 38rem;
    }

    &-description {
      font-weight: 300;
    }

    &-comision {
      font-weight: 300;
      margin: 2.5rem 0;
      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }

    &-payments {
      font-weight: 300;
      .table {
        justify-content: space-between;
        max-width: 40rem;
      }

      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@media (max-width: 1300px) {
  .responsive {
    flex-direction: column;
    align-items: center;
  }

  .section-withBeluga {
    padding: 0 4rem;
    background-image: none;
    background-position: bottom -10rem center, bottom 1rem right 2rem;
    justify-content: center;

    .content {
      &-left {
        text-align: center;

        img {
          width: 7rem;
        }
      }

      &-title {
        text-align: center;
      }
    }
  }
}

@media (min-width: 2560px) {
  .section-withBeluga {
    background-position: -60% 50%, 25% 10%;
  }
}

@media (min-width: 3200px) {
  .section-withBeluga {
    min-height: 70vh;
    background-position: -60% 50%, 25% 10%;
  }
}
