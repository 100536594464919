@import './../../../vars.scss';


.content-pos {
  width: 100vw;
  position: relative;
}

.abs {
  position: absolute;
}

.tag {
  text-align: right;
  line-height: .5;
  font-size: 1.2rem;
  color: #0c6da8;
}

.pos {
  &-header {
    line-height: .5;
    align-items: center;

    &__title {
      font-size: 1.7rem;

      h1 {
        font-weight: 600;
        color: #9C0AE8;
      }

      h2 {
        font-weight: 400;
        color: #9C0AE8;
      }
    }
  }

  &-content {
    max-width: 35rem;
    
    p {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 2rem 0;
    }

    .os {
      display: flex;
      max-width: 25em;
      justify-content: space-between;
      align-items: center;

      .microsoft {
        width: 12rem;
        height: 2.8rem;
      }
    }
  }

  .mobile-terminal {
    overflow: hidden;
    position: relative;
    max-width: 80vw;
    min-height: 25rem;
    img {
      width: 140%;
      margin-left: 5rem;
    }

    .pinpad-mob {
      top: 0;
      left: 0;
    }
    
    .printer-mob {
      bottom: 0;
      right: 0;
    }
  }
}

@media (max-width: 1024px) {
  .pos {
    justify-content: center;
  }

  .tag {
    text-align: right;
    line-height: 1;
    font-size: 1.2rem;
    color: $purple-espiral;
  }
  
  .pinpad {
    display: none;
  }
  
  .printer {
    display: none;
  }

  .pos {
    padding: 8rem 4rem 0;

    &-header {
      text-align: center;
      line-height: .5;
      align-items: center;
    }

    &-content {
      .os {
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin: 2.5em 0;

        img {
          width: 8%;
          height: 8%;

          &:last-child {
            width: 40%;
            height: 40%;
          }
        }
      }
    }
  }

  .mobile-terminal {
    max-width: 80vw;
    min-height: 25rem;
    overflow: hidden;
    margin: auto;
    position: relative;

    img {
      width: 40rem;
      max-width: 40rem;
      margin: 0 0 0 7rem;
      display: block;
    }

    .pinpad-mob {
      text-align: left;
      bottom: 5em;
      left: 0;
    }
    
    .printer-mob {
      bottom: 0;
      right: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .content-pos {
    .mobile-terminal {
      max-width: 80vw;
      min-height: 30rem;
      overflow: hidden;
      margin: auto;
  
      img {
        width: 100%;
        margin-left: 5rem;
        max-width: 429px;
      }
  
      .pinpad-mob {
        top: 20rem;
        left: 1rem;
      }
      
      .printer-mob {
        top: 0;
        right: 0;
      }
    }
  }
}

@media (min-width: 1025px) {
  .content-pos {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-terminal {
    max-width: 50rem;
    overflow: hidden;
    height: 41.5rem;
    position: relative;

    img {
      width: 160%;
    }

    .pinpad-mob {
      bottom: 5.5rem;
      left: 2rem;
    }

    .printer-mob {
      bottom: -.5rem;
      right: 14rem;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1550px) {
  .content-pos {
    display: flex;
    min-height: 65vw;
    align-items: center;
    justify-content: center;
  }

  .mobile-terminal {
    max-width: 40rem;
    overflow: hidden;
    height: 35rem;
    position: relative;

    img {
      width: 160%;
    }

    .pinpad-mob {
      bottom: 5rem;
      left: 2rem;
    }

    .printer-mob {
      bottom: -.5rem;
      right: 8rem;
    }
  }
}

@media (min-width: 1600px) {
  .content-pos {
    display: flex;
    min-height: 80vh;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 3200px) {
  .content-pos {
    height: 70vh;
    min-height: 70vh;
  }
}
