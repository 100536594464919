$xl: 1920px;
$ml: 1440px;
$lg: 1280px;
$md: 960px;
$sm: 600px;
$xs: 0px;


$blue-dark-beluga: #00373f;
$blue-light-beluga: #06a0b5;
$skyblue-light-beluga: #1ffff9;
$blue-espiral: #0C6DA8;
$light-blue-espiral: #14ABF7;
$green-espiral: #0CE588;
$purple-espiral: #9C0AE8;
$line-form: #00aad1;
$title-contact: #1ffff9;