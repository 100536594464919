@import './../../../vars.scss';

.section-get-espiral {
  background-image: url('/img/020_bannerfinal.jpg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .espiral-content {
    text-align: center;

    h1 {
      color: $blue-espiral;
      font-size: 2.5rem;
      span:first-child {
        font-weight: 300;
      }
      span:last-child {
        color: $purple-espiral;
      }
    }

    .header__button {
      margin: 3.5rem 0;
    }

    .btn-send {
      color: white !important;
      font-size: 1.3em;
      font-weight: 500;
      border: 5px solid white !important;
      padding: 0.1em 2.3em;
      border-radius: 10px;
      transition: all 250ms;
      margin: 2rem 0;

      &:hover {
        border: 5px solid white !important;
        color: $light-blue-espiral;
      }
    }

    p {
      margin: 2rem auto;
      font-size: 2rem;
      font-weight: 300;
      color: $blue-espiral;
      span {
        font-weight: 400;
      }
    }
  }
}
